import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
import { BlockComponent } from "../../framework/src/BlockComponent";
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import CustomTextItem from "./CustomTextItem";
import NavigationBlock from "../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from '../../framework/src/SingletonFactory';

import HomeScreenAdapter from '../../blocks/adapters/src/HomeScreenAdapter';
import InfoPageAdapter from '../../blocks/adapters/src/InfoPageAdapter';
import AlertPageWebAdapter from "../../blocks/adapters/src/AlertPageWebAdapter";

// Customizable Area Start
import PrivacyPolicyAdapter from "../../blocks/adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../blocks/adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../blocks/adapters/src/SplashScreenAdapter";
import MobilePhoneToOTPAdapter from "../../blocks/adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../blocks/adapters/src/OtpToNewPasswordAdapter";
import ForgotPasswordAdapter from "../../blocks/adapters/src/ForgotPasswordAdapter";
import MobilePhoneLogInAdapter from "../../blocks/adapters/src/MobilePhoneLogInAdapter";
import MobilePhoneToAdditionalDetailsAdapter from "../../blocks/adapters/src/MobilePhoneToAdditionalDetailsAdapter";
import SocialMediaLogInAdapter from "../../blocks/adapters/src/SocialMediaLogInAdapter";
import EmailAccountLogInAdapter from "../../blocks/adapters/src/EmailAccountLogInAdapter";
import EmailAccountSignUpAdapter from "../../blocks/adapters/src/EmailAccountSignUpAdapter";
import OnboardingAdapter from "../../blocks/adapters/src/OnboardingAdapter";

//Assembler generated adapters start

//Assembler generated adapters end



//Assembler generated adapters start

//Assembler generated adapters end



//Assembler generated adapters start
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();
const forgotPasswordAdapter = new ForgotPasswordAdapter();
const mobilePhoneLogInAdapter = new MobilePhoneLogInAdapter();
const mobilePhoneToAdditionalDetailsAdapter = new MobilePhoneToAdditionalDetailsAdapter();
const socialMediaLogInAdapter = new SocialMediaLogInAdapter();
const emailAccountLogInAdapter = new EmailAccountLogInAdapter();
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const onboardingAdapter = new OnboardingAdapter();

//Assembler generated adapters end



const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
// Customizable Area End


const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageWebAdapter = new AlertPageWebAdapter()

const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure."
  // Customizable Area End
});

interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
interface S { }

interface SS { }

class HomeScreen extends BlockComponent<Props, S, SS> {

  static instance:HomeScreen;

  constructor(props: Props) {
    super(props);
    HomeScreen.instance = this;
  }

  render() {
    const { navigation } = this.props;
    const _this = this;

    return (
      <SafeAreaView>
        <ScrollView contentContainerStyle={styles.scrollView} bounces={false}>
          <View style={styles.container}>
            <View style={styles.header}>
              <Text style={styles.welcome}>
                Welcome to MyRankELearningApp!
              </Text>
            </View>

            <Text style={styles.instructions}>{instructions}</Text>
            <Text style={styles.header}>DEFAULT BLOCKS</Text>
            <CustomTextItem
              content={'InfoPage'}
              onPress={() => navigation.navigate("InfoPage")}
            />
            <CustomTextItem
              content={'Alert'}
              onPress={() => this.showAlert("Example", "This happened")}
            />
<CustomTextItem content={'Pushnotifications'}  onPress={() => navigation.navigate("Pushnotifications")} />
<CustomTextItem content={'core'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Notifications'}  onPress={() => navigation.navigate("Notifications")} />
<CustomTextItem content={'ApiIntegration'}  onPress={() => navigation.navigate("ApiIntegration")} />
<CustomTextItem content={'VisualAnalytics'}  onPress={() => navigation.navigate("VisualAnalytics")} />
<CustomTextItem content={'OTPInputAuth'}  onPress={() => navigation.navigate("OTPInputAuth")} />
<CustomTextItem content={'ForgotPassword'}  onPress={() => navigation.navigate("ForgotPassword")} />
<CustomTextItem content={'CountryCodeSelector'}  onPress={() => navigation.navigate("CountryCodeSelector")} />
<CustomTextItem content={'MobileAccountLoginBlock'}  onPress={() => navigation.navigate("MobileAccountLoginBlock")} />
<CustomTextItem content={'PhoneNumberInput'}  onPress={() => navigation.navigate("PhoneNumberInput")} />
<CustomTextItem content={'Videos'}  onPress={() => navigation.navigate("Videos")} />
<CustomTextItem content={'Scheduling'}  onPress={() => navigation.navigate("Scheduling")} />
<CustomTextItem content={'SocialMediaAccountRegistrationScreen'}  onPress={() => navigation.navigate("SocialMediaAccountRegistrationScreen")} />
<CustomTextItem content={'social-media-account'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'EmailAccountLoginBlock'}  onPress={() => navigation.navigate("EmailAccountLoginBlock")} />
<CustomTextItem content={'EmailAccountRegistration'}  onPress={() => navigation.navigate("EmailAccountRegistration")} />
<CustomTextItem content={'SocialMediaAccountLoginScreen'}  onPress={() => navigation.navigate("SocialMediaAccountLoginScreen")} />
<CustomTextItem content={'UserStatus'}  onPress={() => navigation.navigate("UserStatus")} />
<CustomTextItem content={'Dashboard'}  onPress={() => navigation.navigate("Dashboard")} />
<CustomTextItem content={'Contactus'}  onPress={() => navigation.navigate("Contactus")} />
<CustomTextItem content={'utilities'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'PostCreation'}  onPress={() => navigation.navigate("PostCreation")} />
<CustomTextItem content={'Categoriessubcategories'}  onPress={() => navigation.navigate("Categoriessubcategories")} />
<CustomTextItem content={'Invitefriends'}  onPress={() => navigation.navigate("Invitefriends")} />
<CustomTextItem content={'Splashscreen'}  onPress={() => navigation.navigate("Splashscreen")} />
<CustomTextItem content={'Sorting'}  onPress={() => navigation.navigate("Sorting")} />
<CustomTextItem content={'Catalogue'}  onPress={() => navigation.navigate("Catalogue")} />
<CustomTextItem content={'Filteritems'}  onPress={() => navigation.navigate("Filteritems")} />
<CustomTextItem content={'AdvancedSearch'}  onPress={() => navigation.navigate("AdvancedSearch")} />
<CustomTextItem content={'OrderManagement'}  onPress={() => navigation.navigate("OrderManagement")} />
<CustomTextItem content={'comments'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Onboardingguide'}  onPress={() => navigation.navigate("Onboardingguide")} />
<CustomTextItem content={'CarouselDisplay'}  onPress={() => navigation.navigate("CarouselDisplay")} />
<CustomTextItem content={'NavigationMenu'}  onPress={() => navigation.navigate("NavigationMenu")} />
<CustomTextItem content={'Customisableusersubscriptions'}  onPress={() => navigation.navigate("Customisableusersubscriptions")} />
<CustomTextItem content={'Customform'}  onPress={() => navigation.navigate("Customform")} />
<CustomTextItem content={'LandingPage'}  onPress={() => navigation.navigate("LandingPage")} />
<CustomTextItem content={'Settings5'}  onPress={() => navigation.navigate("Settings5")} />
<CustomTextItem content={'CfTestManagement2'}  onPress={() => navigation.navigate("CfTestManagement2")} />
<CustomTextItem content={'Payments'}  onPress={() => navigation.navigate("Payments")} />
<CustomTextItem content={'BulkUploading'}  onPress={() => navigation.navigate("BulkUploading")} />
<CustomTextItem content={'CfMultipleDomain2'}  onPress={() => navigation.navigate("CfMultipleDomain2")} />
<CustomTextItem content={'Scoring'}  onPress={() => navigation.navigate("Scoring")} />
<CustomTextItem content={'Webinars'}  onPress={() => navigation.navigate("Webinars")} />
<CustomTextItem content={'CfDisqusApiForCommentTracking2'}  onPress={() => navigation.navigate("CfDisqusApiForCommentTracking2")} />
<CustomTextItem content={'DataMigration2'}  onPress={() => navigation.navigate("DataMigration2")} />
<CustomTextItem content={'SearchHistory'}  onPress={() => navigation.navigate("SearchHistory")} />
<CustomTextItem content={'CfAndroidTabCompatible2'}  onPress={() => navigation.navigate("CfAndroidTabCompatible2")} />
<CustomTextItem content={'Upvotedownvote'}  onPress={() => navigation.navigate("Upvotedownvote")} />
<CustomTextItem content={'ProjectTemplates'}  onPress={() => navigation.navigate("ProjectTemplates")} />
<CustomTextItem content={'QuestionBank'}  onPress={() => navigation.navigate("QuestionBank")} />
<CustomTextItem content={'ReviewPrompt'}  onPress={() => navigation.navigate("ReviewPrompt")} />
<CustomTextItem content={'ReviewAndApproval'}  onPress={() => navigation.navigate("ReviewAndApproval")} />
<CustomTextItem content={'RecommendationEngine4'}  onPress={() => navigation.navigate("RecommendationEngine4")} />
<CustomTextItem content={'SmsSettings'}  onPress={() => navigation.navigate("SmsSettings")} />
<CustomTextItem content={'AdminConsole3'}  onPress={() => navigation.navigate("AdminConsole3")} />
<CustomTextItem content={'DataImportexportcsv'}  onPress={() => navigation.navigate("DataImportexportcsv")} />
<CustomTextItem content={'Leaderboard'}  onPress={() => navigation.navigate("Leaderboard")} />
<CustomTextItem content={'Pagination2'}  onPress={() => navigation.navigate("Pagination2")} />
<CustomTextItem content={'StoreLocator'}  onPress={() => navigation.navigate("StoreLocator")} />
<CustomTextItem content={'RolesPermissions2'}  onPress={() => navigation.navigate("RolesPermissions2")} />
<CustomTextItem content={'UploadMedia2'}  onPress={() => navigation.navigate("UploadMedia2")} />
<CustomTextItem content={'PeopleManagement2'}  onPress={() => navigation.navigate("PeopleManagement2")} />
<CustomTextItem content={'VideoEmbeddingYoutube'}  onPress={() => navigation.navigate("VideoEmbeddingYoutube")} />
<CustomTextItem content={'VideoLibrary'}  onPress={() => navigation.navigate("VideoLibrary")} />
<CustomTextItem content={'VideoManagement'}  onPress={() => navigation.navigate("VideoManagement")} />
<CustomTextItem content={'CustomAdvertisements'}  onPress={() => navigation.navigate("CustomAdvertisements")} />
<CustomTextItem content={'Download'}  onPress={() => navigation.navigate("Download")} />
<CustomTextItem content={'DownloadOptions'}  onPress={() => navigation.navigate("DownloadOptions")} />
<CustomTextItem content={'DynamicContent'}  onPress={() => navigation.navigate("DynamicContent")} />
<CustomTextItem content={'Feedback'}  onPress={() => navigation.navigate("Feedback")} />
<CustomTextItem content={'EmailNotifications'}  onPress={() => navigation.navigate("EmailNotifications")} />
<CustomTextItem content={'UsageInsights'}  onPress={() => navigation.navigate("UsageInsights")} />
<CustomTextItem content={'Gamification'}  onPress={() => navigation.navigate("Gamification")} />
<CustomTextItem content={'SalesReporting'}  onPress={() => navigation.navigate("SalesReporting")} />
<CustomTextItem content={'ManageBlogComments'}  onPress={() => navigation.navigate("ManageBlogComments")} />
<CustomTextItem content={'Testimonials'}  onPress={() => navigation.navigate("Testimonials")} />
<CustomTextItem content={'TicketSystem'}  onPress={() => navigation.navigate("TicketSystem")} />
<CustomTextItem content={'TimeTracker'}  onPress={() => navigation.navigate("TimeTracker")} />
<CustomTextItem content={'AssessmentTest'}  onPress={() => navigation.navigate("AssessmentTest")} />
<CustomTextItem content={'BlogPostsManagement'}  onPress={() => navigation.navigate("BlogPostsManagement")} />
<CustomTextItem content={'CfZoomIntegration16'}  onPress={() => navigation.navigate("CfZoomIntegration16")} />
<CustomTextItem content={'Bookmark2'}  onPress={() => navigation.navigate("Bookmark2")} />
<CustomTextItem content={'BreadcrumbNavigation'}  onPress={() => navigation.navigate("BreadcrumbNavigation")} />
<CustomTextItem content={'BulkCommentManagement'}  onPress={() => navigation.navigate("BulkCommentManagement")} />
<CustomTextItem content={'IntegrationWithMathJax2'}  onPress={() => navigation.navigate("IntegrationWithMathJax2")} />
<CustomTextItem content={'ContentManagement'}  onPress={() => navigation.navigate("ContentManagement")} />
<CustomTextItem content={'CfBadges'}  onPress={() => navigation.navigate("CfBadges")} />
<CustomTextItem content={'CfWhatsappIntegration44'}  onPress={() => navigation.navigate("CfWhatsappIntegration44")} />
<CustomTextItem content={'CommunityForum'}  onPress={() => navigation.navigate("CommunityForum")} />
<CustomTextItem content={'CustomisableUserProfiles'}  onPress={() => navigation.navigate("CustomisableUserProfiles")} />
<CustomTextItem content={'LiveChat2'}  onPress={() => navigation.navigate("LiveChat2")} />
<CustomTextItem content={'LikeAPost'}  onPress={() => navigation.navigate("LikeAPost")} />
<CustomTextItem content={'MultibrowserSupport'}  onPress={() => navigation.navigate("MultibrowserSupport")} />
<CustomTextItem content={'PaymentAdmin2'}  onPress={() => navigation.navigate("PaymentAdmin2")} />

<CustomTextItem content={'StripeIntegration'}  onPress={() => navigation.navigate("StripeIntegration")} />
<CustomTextItem content={'ImportExportData'}  onPress={() => navigation.navigate("ImportExportData")} />
<CustomTextItem content={'Chat'}  onPress={() => navigation.navigate("Chat")} />
<CustomTextItem content={'AdminConsole'}  onPress={() => navigation.navigate("AdminConsole")} />
<CustomTextItem content={'RolesPermissions'}  onPress={() => navigation.navigate("RolesPermissions")} />
<CustomTextItem content={'EmailNotifications2'}  onPress={() => navigation.navigate("EmailNotifications2")} />
<CustomTextItem content={'PaymentAdmin'}  onPress={() => navigation.navigate("PaymentAdmin")} />

<CustomTextItem content={'Gamification'}  onPress={() => navigation.navigate("Gamification")} />
<CustomTextItem content={'AdminConsole2'}  onPress={() => navigation.navigate("AdminConsole2")} />
<CustomTextItem content={'Settings'}  onPress={() => navigation.navigate("Settings")} />

          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 1,
    height: Platform.OS === "web" ? '100vh' : 'auto',
    backgroundColor: "#F5FCFF"
  },
  container: {
    flex: 1
  },
  welcome: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "bold",
    color: "white"
  },
  instructions: {
    textAlign: "center",
    color: "#6200EE",
    marginBottom: 5,
    fontWeight: 'bold',
    fontSize: 16,

    padding: 10
  },
  button: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  header: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  item: {
    backgroundColor: '#00000000',
    padding: 18,
    color: '#6200EE',
    fontSize: 16,
    fontWeight: 'normal'
  }
});
// Customizable Area End
export default HomeScreen;