// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import CustomisableUserProfiles from "../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles";
import VideoLibrary from "../../blocks/VideoLibrary/src/VideoLibrary";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import OrderDetails from "../../blocks/ordermanagement/src/OrderDetails";
import RecommendationEngine4 from "../../blocks/RecommendationEngine4/src/RecommendationEngine4";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import QuestionBank from "../../blocks/QuestionBank/src/QuestionBank";
import BreadcrumbNavigation from "../../blocks/BreadcrumbNavigation/src/BreadcrumbNavigation";
import BlogPostsManagement from "../../blocks/BlogPostsManagement/src/BlogPostsManagement";
import Leaderboard from "../../blocks/Leaderboard/src/Leaderboard";
import DataImportexportcsv from "../../blocks/DataImportexportcsv/src/DataImportexportcsv";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import CfDisqusApiForCommentTracking2 from "../../blocks/CfDisqusApiForCommentTracking2/src/CfDisqusApiForCommentTracking2";
import ReviewPrompt from "../../blocks/ReviewPrompt/src/ReviewPrompt";
import Testimonials from "../../blocks/Testimonials/src/Testimonials";
import ProjectTemplates from "../../blocks/ProjectTemplates/src/ProjectTemplates";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Download from "../../blocks/Download/src/Download";
import DataMigration2 from "../../blocks/DataMigration2/src/DataMigration2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import LikeAPost from "../../blocks/LikeAPost/src/LikeAPost";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import Payments from "../../blocks/Payments/src/Payments";
import CfTestManagement2 from "../../blocks/CfTestManagement2/src/CfTestManagement2";
import SearchHistory from "../../blocks/SearchHistory/src/SearchHistory";
import Videos from "../../blocks/videos/src/Videos";
import Pagination2 from "../../blocks/Pagination2/src/Pagination2";
import SmsSettings from "../../blocks/SmsSettings/src/SmsSettings";
import Gamification from "../../blocks/Gamification/src/Gamification";
import PeopleManagement2 from "../../blocks/PeopleManagement2/src/PeopleManagement2";
import PaymentAdmin2 from "../../blocks/PaymentAdmin2/src/PaymentAdmin2";
import Customform from "../../blocks/customform/src/Customform";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import CfWhatsappIntegration44 from "../../blocks/CfWhatsappIntegration44/src/CfWhatsappIntegration44";
import UsageInsights from "../../blocks/UsageInsights/src/UsageInsights";
import TicketSystem from "../../blocks/TicketSystem/src/TicketSystem";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import MultibrowserSupport from "../../blocks/MultibrowserSupport/src/MultibrowserSupport";
import Notifications from "../../blocks/notifications/src/Notifications";
import CfMultipleDomain2 from "../../blocks/CfMultipleDomain2/src/CfMultipleDomain2";
import DynamicContent from "../../blocks/DynamicContent/src/DynamicContent";
import CfZoomIntegration16 from "../../blocks/CfZoomIntegration16/src/CfZoomIntegration16";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import Invitefriends from "../../blocks/invitefriends/src/Invitefriends";
import CfBadges from "../../blocks/CfBadges/src/CfBadges";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import ManageBlogComments from "../../blocks/ManageBlogComments/src/ManageBlogComments";
import CfAndroidTabCompatible2 from "../../blocks/CfAndroidTabCompatible2/src/CfAndroidTabCompatible2";
import CustomAdvertisements from "../../blocks/CustomAdvertisements/src/CustomAdvertisements";
import BulkUploading from "../../blocks/BulkUploading/src/BulkUploading";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Scoring from "../../blocks/Scoring/src/Scoring";
import Feedback from "../../blocks/Feedback/src/Feedback";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import UserStatus from "../../blocks/userstatus/src/UserStatus";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import ReviewApprovalAdmin from "../../blocks/ReviewAndApproval/src/ReviewApprovalAdmin";
import ReviewApprovalBasicUser from "../../blocks/ReviewAndApproval/src/ReviewApprovalBasicUser";
import Webinars from "../../blocks/Webinars/src/Webinars";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Sorting from "../../blocks/sorting/src/Sorting";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import BulkCommentManagement from "../../blocks/BulkCommentManagement/src/BulkCommentManagement";
import DownloadOptions from "../../blocks/DownloadOptions/src/DownloadOptions";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import VideoEmbeddingYoutube from "../../blocks/VideoEmbeddingYoutube/src/VideoEmbeddingYoutube";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import AssessmentTest from "../../blocks/AssessmentTest/src/AssessmentTest";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import LiveChat2 from "../../blocks/LiveChat2/src/LiveChat2";
import VideoManagement from "../../blocks/VideoManagement/src/VideoManagement";
import TimeTracker from "../../blocks/TimeTracker/src/TimeTracker";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import Bookmark2 from "../../blocks/Bookmark2/src/Bookmark2";
import CommunityForum from "../../blocks/CommunityForum/src/CommunityForum";
import Upvotedownvote from "../../blocks/Upvotedownvote/src/Upvotedownvote";
import CreateComment from "../../blocks/comments/src/CreateComment";
import IntegrationWithMathJax2 from "../../blocks/IntegrationWithMathJax2/src/IntegrationWithMathJax2";
import StoreLocator from "../../blocks/StoreLocator/src/StoreLocator";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";
import SalesReporting from "../../blocks/SalesReporting/src/SalesReporting";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import PaymentAdmin from "../../blocks/PaymentAdmin/src/PaymentAdmin";
import StripeIntegration from "../../blocks/stripepayments/src/StripeIntegration";
import RolesPermissions from "../../blocks/RolesPermissions/src/RolesPermissions";
import AdminConsole from "../../blocks/AdminConsole/src/AdminConsole";
import EmailNotifications2 from "../../blocks/EmailNotifications2/src/EmailNotifications2";
import Chat from "../../blocks/chat/src/Chat";
import ChatView from "../../blocks/chat/src/ChatView";
import Settings from "../../blocks/Settings/src/Settings";
import AdminConsole2 from "../../blocks/AdminConsole2/src/AdminConsole2";





const routeMap = {
Settings:{
 component:Settings,
path:"/Settings"},
AdminConsole2:{
 component:AdminConsole2,
path:"/AdminConsole2"},
Gamification:{
 component:Gamification,
path:"/Gamification"},

ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
PaymentAdmin:{
 component:PaymentAdmin,
path:"/PaymentAdmin"},
StripeIntegration:{
 component:StripeIntegration,
path:"/StripeIntegration"},
RolesPermissions:{
 component:RolesPermissions,
path:"/RolesPermissions"},
AdminConsole:{
 component:AdminConsole,
path:"/AdminConsole"},
EmailNotifications2:{
 component:EmailNotifications2,
path:"/EmailNotifications2"},
Chat:{
 component:Chat,
path:"/Chat"},
ChatView:{
 component:ChatView,
path:"/ChatView"},

VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
CustomisableUserProfiles:{
 component:CustomisableUserProfiles,
path:"/CustomisableUserProfiles"},
VideoLibrary:{
 component:VideoLibrary,
path:"/VideoLibrary"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
OrderDetails:{
 component:OrderDetails,
path:"/OrderDetails"},
RecommendationEngine4:{
 component:RecommendationEngine4,
path:"/RecommendationEngine4"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
QuestionBank:{
 component:QuestionBank,
path:"/QuestionBank"},
BreadcrumbNavigation:{
 component:BreadcrumbNavigation,
path:"/BreadcrumbNavigation"},
BlogPostsManagement:{
 component:BlogPostsManagement,
path:"/BlogPostsManagement"},
Leaderboard:{
 component:Leaderboard,
path:"/Leaderboard"},
DataImportexportcsv:{
 component:DataImportexportcsv,
path:"/DataImportexportcsv"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
CfDisqusApiForCommentTracking2:{
 component:CfDisqusApiForCommentTracking2,
path:"/CfDisqusApiForCommentTracking2"},
ReviewPrompt:{
 component:ReviewPrompt,
path:"/ReviewPrompt"},
Testimonials:{
 component:Testimonials,
path:"/Testimonials"},
ProjectTemplates:{
 component:ProjectTemplates,
path:"/ProjectTemplates"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
Download:{
 component:Download,
path:"/Download"},
DataMigration2:{
 component:DataMigration2,
path:"/DataMigration2"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
LikeAPost:{
 component:LikeAPost,
path:"/LikeAPost"},
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
Payments:{
 component:Payments,
path:"/Payments"},
CfTestManagement2:{
 component:CfTestManagement2,
path:"/CfTestManagement2"},
SearchHistory:{
 component:SearchHistory,
path:"/SearchHistory"},
Videos:{
 component:Videos,
path:"/Videos"},
Pagination2:{
 component:Pagination2,
path:"/Pagination2"},
SmsSettings:{
 component:SmsSettings,
path:"/SmsSettings"},
Gamification:{
 component:Gamification,
path:"/Gamification"},
PeopleManagement2:{
 component:PeopleManagement2,
path:"/PeopleManagement2"},
PaymentAdmin2:{
 component:PaymentAdmin2,
path:"/PaymentAdmin2"},
Customform:{
 component:Customform,
path:"/Customform"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
CfWhatsappIntegration44:{
 component:CfWhatsappIntegration44,
path:"/CfWhatsappIntegration44"},
UsageInsights:{
 component:UsageInsights,
path:"/UsageInsights"},
TicketSystem:{
 component:TicketSystem,
path:"/TicketSystem"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
MultibrowserSupport:{
 component:MultibrowserSupport,
path:"/MultibrowserSupport"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
CfMultipleDomain2:{
 component:CfMultipleDomain2,
path:"/CfMultipleDomain2"},
DynamicContent:{
 component:DynamicContent,
path:"/DynamicContent"},
CfZoomIntegration16:{
 component:CfZoomIntegration16,
path:"/CfZoomIntegration16"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
Invitefriends:{
 component:Invitefriends,
path:"/Invitefriends"},
CfBadges:{
 component:CfBadges,
path:"/CfBadges"},
AdminConsole3:{
 component:AdminConsole3,
path:"/AdminConsole3"},
Settings5:{
 component:Settings5,
path:"/Settings5"},
ManageBlogComments:{
 component:ManageBlogComments,
path:"/ManageBlogComments"},
CfAndroidTabCompatible2:{
 component:CfAndroidTabCompatible2,
path:"/CfAndroidTabCompatible2"},
CustomAdvertisements:{
 component:CustomAdvertisements,
path:"/CustomAdvertisements"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Scoring:{
 component:Scoring,
path:"/Scoring"},
Feedback:{
 component:Feedback,
path:"/Feedback"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
UserStatus:{
 component:UserStatus,
path:"/UserStatus"},
ContentManagement:{
 component:ContentManagement,
path:"/ContentManagement"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
ReviewApprovalAdmin:{
  component:ReviewApprovalAdmin,
 path:"/ReviewApprovalAdmin"},
ReviewApprovalBasicUser:{
 component:ReviewApprovalBasicUser,
path:"/ReviewApprovalBasicUser"},
Webinars:{
 component:Webinars,
path:"/Webinars"},
UploadMedia2:{
 component:UploadMedia2,
path:"/UploadMedia2"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
BulkCommentManagement:{
 component:BulkCommentManagement,
path:"/BulkCommentManagement"},
DownloadOptions:{
 component:DownloadOptions,
path:"/DownloadOptions"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
VideoEmbeddingYoutube:{
 component:VideoEmbeddingYoutube,
path:"/VideoEmbeddingYoutube"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
AssessmentTest:{
 component:AssessmentTest,
path:"/AssessmentTest"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
Onboardingguide:{
 component:Onboardingguide,
path:"/Onboardingguide"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
LiveChat2:{
 component:LiveChat2,
path:"/LiveChat2"},
VideoManagement:{
 component:VideoManagement,
path:"/VideoManagement"},
TimeTracker:{
 component:TimeTracker,
path:"/TimeTracker"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
Bookmark2:{
 component:Bookmark2,
path:"/Bookmark2"},
CommunityForum:{
 component:CommunityForum,
path:"/CommunityForum"},
Upvotedownvote:{
 component:Upvotedownvote,
path:"/Upvotedownvote"},
Comment:{
 component:Comment,
path:"/Comment"},
CreateComment:{
 component:CreateComment,
path:"/CreateComment"},
IntegrationWithMathJax2:{
 component:IntegrationWithMathJax2,
path:"/IntegrationWithMathJax2"},
StoreLocator:{
 component:StoreLocator,
path:"/StoreLocator"},
EmailNotifications:{
 component:EmailNotifications,
path:"/EmailNotifications"},
SalesReporting:{
 component:SalesReporting,
path:"/SalesReporting"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;